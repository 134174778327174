:focus {
  outline: 0 !important;
}

/*.app-header {
  background-color: #FE5522
}*/

/* BEGIN Admin Button Drawer */
/* .app-header .navbar-brand img {
  cursor: pointer;
} */
.app-header .navbar-toggler {
  min-width: 0;
}
.app-header .navbar-toggler-icon {
  background: url("../assets/img/icon/menu.png");
  background-size: cover;
}
.app-header .navbar-toggler-icon:hover,
.app-header .navbar-toggler:hover .navbar-toggler-icon {
  background: url("../assets/img/icon/menu_hover.png");
  background-size: cover;
}
/* END Admin Button Drawer */

/* BEGIN Breadcrumb */
.breadcrumb {
  background: transparent;
  border-color: transparent;
  margin-bottom: 0.25rem;
}
.breadcrumb-item a {
  color: #42b549;
}
/* END Breadcrumb */

.btn-icon-user {
  color: #ffffff;
  font-size: 27px;
  padding-bottom: 3px;
  padding-top: 3px;
}

.btn-delete {
  background: transparent;
  border: unset;
  color: #f86c6b;
  font-size: 18px;
  padding: unset;
}

.btn-delete:hover {
  background: transparent;
  border: unset;
  padding: unset;
}

.btn-delete.btn-secondary:not(:disabled):not(.disabled):active {
  background: transparent;
  border: unset;
  box-shadow: unset;
  /* color: #f86c6b; */
  color: #000000;
}

.btn-green {
  color: #ffffff;
  background-color: #42b549;
  border-color: #42b549;
  border-radius: 50em;
  font-size: 1rem;
  /* max-width: 185px;
  width: 100%; */
}
.btn-green:hover,
.btn-secondary.disabled,
.btn-secondary:disabled,
.btn-secondary:not(:disabled):not(.disabled):active {
  color: #ffffff;
  background-color: #3ea662;
  border-color: #3a9d5d;
}

.btn-red {
  color: #ffffff;
  background-color: #f86c6b;
  border-color: #f86c6b;
  border-radius: 50em;
  font-size: 1rem;
  /* max-width: 185px;
  width: 100%; */
}

.btn-non-label {
  margin-top: 28px;
  width: 100%;
}

.btn-white {
  color: #42B549;
  background-color: #ffffff;
  border-color: #42B549;
  border-radius: 50em;
  font-size: 1rem;
}
.btn-white:hover {
  color: #42b549;
  background-color: #f7f4f4;
  border-color: #42b549;
}

.btn-export {
  background: #42B549;
  display: block;
  height: 35px;
}
.btn-export:hover {
  color: #FFFFFF;
  background-color: #3EA662;
  border-color: #3A9D5D;
}

.btn-upload-approve {
  background: unset;
  border: unset;
  cursor: pointer;
  color: #42B549;
  font-size: 16px;
  padding: unset;
  text-decoration: underline;
}
.btn-upload-reject {
  background: unset;
  border: unset;
  cursor: pointer;
  color: #e01414;
  font-size: 16px;
  padding: unset;
  text-decoration: underline;
}

.border-t-2-gray {
  border-top: 2px solid #e8e8e8;
}

button.disabled:hover,
input.form-control:disabled {
  cursor: not-allowed;
}

/*William*/
.form-check-input-logcall {
  position: absolute;
  margin-top: 0.3rem;
  height: 15px !important;
  margin-left: -1.25rem;
}
/**/

.bg-white {
  background: white;
}

.custom-tab-style a.active.nav-link {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top: none;
  border-left: none;
  border-right: none;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.custom-tab-style a.nav-link {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.custom-tab-style .custom-tab-content {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: white;
}

.custom-tab-nav.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent;
}
.nav-tabs .nav-item {
  margin-bottom: 0;
}

.custom-card-style {
  border-radius: 8px;
  border: none;
}

.custom-card-style label {
  font-size: 10px;
  color: #858585;
}

.custom-card-style select {
  height: 40px;
}

/*.custom-card-style input {
  height: 40px;
}*/

.form-control {
  height: 40px;
}


.custom-btn-style {
  height: 40px;
}

.cursor-point {
  cursor: pointer;
}

/* .color-picker-drawer {
  padding: '5px';
  background: '#fff';
  border-radius: '1px';
  box-shadow: '0 0 0 1px rgba(0,0,0,.1)';
  display: 'block';
  cursor: 'pointer';
} */

.ds-none {
  display: none;
}
.ds-block {
  display: block;
}

.fc-aqua {
  color: #63C2DE;
}
.fc-green {
  color: #008000;
}
.fc-grey {
  color: #4A4A4A;
}
.fc-red {
  color: #E01414;
}
.fc-white {
  color: #FFFFFF;
}

.form-control:focus {
  border-color: #8ad4ee;
  box-shadow: 0 0 0 1px rgba(32, 168, 216, 0.25);
}

.form-control:disabled {
  background: hsl(0,0%,95%);
}

.fw-bold {
  font-weight: bold;
}

.hg-100 {
  height: 100%;
}

.header-fixed .app-header {
  background-color: #fe5522;
  border-color: transparent;
  padding-left: 15px;
  padding-right: 15px;
}

input[type="file"].input-file-image {
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}

.input-error {
  background: #FFF8F8;
  border-color: #E01414;
}

.input-success {
  background: #FFFFFF;
  border-color: #E4E7EA;
}

.logo-login {
  background: url("../assets/img/brand/logo-ideal-horizontal.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 120px;
  margin-bottom: 30px;
}

.max-wid-150 {
  max-width: 150px;
}

.m-b-0 {
  margin-bottom: 0px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-b-40 {
  margin-bottom: 40px;
}

.m-l-5 {
  margin-left: 5px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-l-min-15 {
  margin-left: -15px;
}
.m-r-min-15 {
  margin-right: -15px;
}

.m-l-min-20 {
  margin-left: -20px;
}
.m-r-min-20 {
  margin-right: -20px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-5 {
  margin-top: 5px;
}

/* .m-0 {
  margin: 0;
} */

.nav-tabs .nav-link.active,
.custom-tab-nav.nav-tabs .nav-link:hover {
  color: #42b549;
}

.pd-20 {
  padding: 20px;
}

.p-b-0 {
  padding-bottom: 0 !important;
}
.p-b-16 {
  padding-bottom: 16px;
}

.p-l-8 {
  padding-left: 8px;
}
.p-l-10 {
  padding-left: 10px;
}
.p-l-15 {
  padding-left: 15px;
}
.p-l-20 {
  padding-left: 20px;
}
.p-l-25 {
  padding-left: 25px;
}
.p-r-10 {
  padding-right: 10px;
}
.p-r-15 {
  padding-right: 15px;
}
.p-r-20 {
  padding-right: 20px;
}
.p-r-25 {
  padding-right: 25px;
}
.p-r-30 {
  padding-right: 30px;
}

.p-lr-15 {
  padding: 0 15px !important;
}

/* BEGIN Admin Sidebar Navigation */
.sidebar,
.sidebar-minimized .sidebar .nav > .nav-dropdown > .nav-dropdown-items {
  background: #ffffff;
}
.sidebar .nav-dropdown-toggle::before {
  transform: rotate(-90deg);
}
.sidebar .nav-dropdown.open > .nav-dropdown-toggle::before {
  transform: rotate(90deg);
}
.sidebar .nav-dropdown.open {
  background: rgba(0, 0, 0, 0.1);
}
.sidebar .nav-dropdown.open .nav-link:hover {
  color: #ffffff;
}
.sidebar .nav-dropdown.open .nav-link,
.sidebar .nav-link,
.sidebar .nav-link .nav-icon {
  color: #4a4a4a;
}
.sidebar .nav-link.active,
.sidebar-minimized .sidebar .nav-item:hover > .nav-link.active {
  background: #fff4f1;
  color: #42b549;
}
.sidebar .nav-link.active .nav-icon,
.sidebar .nav-link.active:hover .nav-icon,
.sidebar .nav-dropdown.open .nav-dropdown-items .nav-item .nav-link.active {
  color: #42b549;
}
/* END Admin Sidebar Navigation */

.style-label label {
  color: #858585;
  font-size: 10px;
  text-transform: uppercase;
}

.table-body-user-document tr td {
  border-top: 1px solid #e8e8e8;
  padding: 10px 20px;
  vertical-align: middle !important;
}

.table-head-user-document tr th {
  border-bottom: 2px solid #e8e8e8;
  padding: 10px 20px;
}

.table-hover .table-body-user-document tr:hover,
.table-striped .table-body-user-document tr:nth-of-type(odd):hover {
  background-color: #fff4f1;
  transition: ease 0.2s;
}

.table-row,
.table-hover .table-body-user-document tr,
.table-striped tbody tr:nth-of-type(odd) {
  /* cursor: pointer; */
  background-color: #ffffff;
  border-bottom: 1px solid #e8e8e8;
}
.table-row:hover {
  transition: ease 0.2s;
  background-color: #fff4f1;
}

.table-link-view span.btn-approve {
  cursor: pointer;
  color: #42b549;
  font-size: 16px;
  text-decoration: underline;
}
.table-link-view span.btn-reject {
  cursor: pointer;
  color: #e01414;
  font-size: 16px;
  margin-left: 30px;
  text-decoration: underline;
}

.text-intial {
  text-transform: initial;
}

.text-spacing-5 {
  letter-spacing: 5px;
}

/* BEGIN Toggle Hide Password */
.toggle-change-input {
  cursor: pointer;
  margin: 7px 10px;
  position: absolute;
  right: 15px;
}
/* END Toggle Hide Password */

.wrap-usersaldo-filter {
  margin: 10px;
  width: 70%;
  z-index: 1;
}

.wrap-userorder-filter {
  margin: 10px;
  width: 50%;
  z-index: 1;
}

.wrap-modal-btn-change {
  padding: 3rem 1.5rem;
}

.wrap-ds-flex {
  display: flex;
  flex-wrap: wrap;
}

.wrap-collection-add-product {
  margin-left: unset;
  margin-right: unset;
  margin-bottom: 1.5rem;
  padding-left: 10px;
  padding-right: 10px;
}

.wid-35 {
  width: 35%;
}
.wid-100 {
  width: 100%;
}

@media only screen and (max-width: 575.98px) {
  .sm-mb-4 {
    margin-bottom: 1.5rem;
  }
}

@media only screen and (min-width: 576px) {
  .custom-btn-style {
    /* float:right; */
    max-width: 150px;
    width: 100%;
  }

  .wrap-image-modal {
    /* background: rgba(0, 0, 0, 0.8); */
    height: 100%;
    margin: unset;
    max-width: 100%;
  }

  .wrap-image-modal .modal-content {
    /* height: 85%; */
    height: 65%;
    margin: auto;
    /* max-width: 85%; */
    max-width: 550px;
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 1s;
    animation-name: zoom;
    animation-duration: 1s;
  }
}

@media only screen and (max-width: 767px) {
  .custom-tab-nav {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .custom-tab-nav.nav-tabs .nav-link {
    height: 100%;
    text-align: center;
  }

  .sm-d-flex {
    display: flex;
  }

  .sm-overflow-x-scroll {
    overflow-x: scroll;
  }

  .sm-m-b-16 {
    margin-bottom: 1rem;
  }

  .sm-m-b-24 {
    margin-bottom: 1.5rem;
  }

  .sm-min-width-0 {
    min-width: 0 !important;
  }
}

@media only screen and (min-width: 991px) {
  .brand-minimized .app-header .navbar-brand {
    width: 100px;
  }
}

.add-button {
  clear: both;
  margin-left: 15px;
  margin-top: 15px;
}

.custom-button {
  width: 200px;
  margin-top: 10px;
}

[class^="MuiPaper-elevation2-"],
[class*=" MuiPaper-elevation2-"] {
  box-shadow: none !important;
}

.no-padding {
  padding: 0;
}

.modal-button {
  flex: 1;
  margin: 5px;
  display: flex;
  justify-content: center;
}

.full-width {
  width: 100%;
}

@media only screen and (min-width: 400px) {
  .modal-button {
    width: 50%;
    margin: 0px;
    padding: 10px;
  }

  .modal-button .modal-cancel-button {
    float: right;
  }

  .modal-button .modal-confirm-button {
    float: left;
  }
}

.loading-container {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 15px;
}

.t-underline {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-thumb-image {
  position: relative;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border: solid #979797 1px;
}
.custom-thumb-image img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)}
  to {-webkit-transform:scale(1)}
}

@keyframes zoom {
  from {transform:scale(0)}
  to {transform:scale(1)}
}

.balance-panel {
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
  align-self: stretch;
  justify-self: stretch;
  height: 100%;
}

.balance-panel::before {
  content: ' ';
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 0;
  opacity: 0.3;
  width: 64px;
  height: 64px;
}

.balance-panel.--credit::before {
  background-image: url(./../assets/img/icon/money-in.png);
}

.balance-panel.--debit::before {
  background-image: url(./../assets/img/icon/money-out.png);
}

.balance-panel.--balance::before {
  background-image: url(./../assets/img/icon/balance.png);
}

.balance-panel__card {
  background-color: white;
  border-radius: 4px;
  position: relative;
}

.balance-panel__label {
  color: #a5a5a5;
}

.balance-panel__value {
  font-weight: 500;
  font-size: .95rem;
}

.balance-panel__note {
  font-size: 11px;
}

.balance-panel__loading {
  width: 38px;
  height: 38px;
}

.balance-panel__loading>div, .balance-panel__loading>div>div {
  padding: 2px 2px;
  width: 32px !important;
  height: 32px !important;
}

.balance-panel__note.--green {
  color: rgb(66, 187, 74);
}

.balance-panel__note.--red {
  color: #e75050;
}

.balance-history-detail__container {
  padding: 4px 32px;
}

.balance-history-detail__table>tbody>tr>td {
  color: rgb(66, 97, 117);
  font-weight: 500;
  font-size: 0.82rem;
}

.balance-history-detail__table>tbody>tr>td:nth-child(2) {
  padding: 0px 24px 0px 2px;
}

.modal-input {
  width: 500px;
  margin: 30px 0;
}

.modal-input p {
  margin: 0;
  padding: 0 10px;
}

.modal-input table {
  margin: 0 auto;
}

.collapsible {
  background-color: rgb(53, 37, 37);
  padding-left: 10px;
  color: white;
  width: 10 0%;
}